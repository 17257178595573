import React from 'react'
import { useIntl } from 'react-intl'
import { Row, Col } from 'react-bootstrap'

import { useAuth } from '../../../Auth'
import { Text, Password, Countries, type FormContextType } from '../../../Forms'
import { useShop } from '../../../Shop'

type RequireMobileType = 'required' | 'optional' | 'not-allowed'

type GetUserProps = {
    className?: string
    requireMobile?: RequireMobileType
    refToFirst?: React.LegacyRef<HTMLInputElement>
    regStatus: 'REGP' | 'REGF'
}

export const GetUser = <Currency extends string, ContentSource extends string>( { className, requireMobile, refToFirst, regStatus }: GetUserProps ) => {

    const intl = useIntl()
    const auth = useAuth()
    const shop = useShop<Currency, ContentSource>()

    return <div className={ className }>
        <Text 
            controlId='email' 
            ref={ refToFirst }
            type='email' 
            className="mb-3"
            disabled={ auth.isAuthenticated }
            required
            placeholder={ intl.formatMessage({ description: 'Shop.Order.Email', defaultMessage: 'Email' }) }
            invalidMsg={ intl.formatMessage({ description: 'Shop.Order.Email.Required', defaultMessage: 'Email is required' }) }
        />
        { regStatus === 'REGF' && !auth.isAuthenticated && <>
            <Password 
                controlId='pwd' 
                className='mb-3'
                placeholder={ intl.formatMessage( { description: 'auth.pwd', defaultMessage: 'Password' } ) } 
                invalidMsg={ intl.formatMessage({ description: 'auth.pwd-required', defaultMessage: 'Please input your Password!' }) }
            />
            <Password 
                controlId='rePwd' 
                className='mb-3'
                placeholder={ intl.formatMessage( { description: 'auth.re-pwd', defaultMessage: 'Repeat Password' } ) } 
                pattern={ ( form: FormContextType ) => (form.data[ 'pwd' ] as string).trim().replace(/[.*+?^${}()|[\]\\]/g, '\\$&') }
                invalidMsg={ intl.formatMessage({ description: 'auth.re-pwd-required', defaultMessage: 'Please re-enter exactly your Password!' }) }
            /> 
        </> }
        <Text 
            controlId='name' 
            className="mb-3"
            required
            placeholder={ intl.formatMessage({ description: 'Shop.Order.Name', defaultMessage: 'Name' }) }
            invalidMsg={ intl.formatMessage({ description: 'Shop.Order.Name.Required', defaultMessage: 'Name is required' }) }
        />
        { requireMobile != 'not-allowed' && <Text 
            controlId='mobile'
            type='phone'
            className="mb-3"
            required={ requireMobile === 'required' }
            placeholder={ intl.formatMessage({ description: 'Shop.Order.Phone', defaultMessage: 'Phone' }) }
            invalidMsg={ intl.formatMessage({ description: 'Shop.Order.Phone.Required', defaultMessage: 'Phone is required' }) }
        /> }
        <Text 
            controlId='street' 
            className='mb-3'
            placeholder={ intl.formatMessage( { description: 'Shop.Order.Street', defaultMessage: 'Street' } ) } 
            required
            invalidMsg={ intl.formatMessage({ description: 'Shop.Order.Street.Required', defaultMessage: 'Please input street!' }) }
        /> 
        <Row>
            <Col xs="4">
                <Text 
                    controlId='zip' 
                    className='mb-3'
                    pattern={ ( form: FormContextType ) => form.data[ 'country' ] as string === 'CZ' ? '^(\\d{3}\\s{0,1}\\d{2})$' : undefined }
                    placeholder={ intl.formatMessage( { description: 'Shop.Order.Zip', defaultMessage: 'ZIP' } ) } 
                    required
                    invalidMsg={ intl.formatMessage({ description: 'Shop.Order.Zip.Required', defaultMessage: 'Please input your ZIP code!' }) }
                /> 
            </Col>
            <Col>
                <Text 
                    controlId='city' 
                    className='mb-3'
                    placeholder={ intl.formatMessage( { description: 'Shop.Order.City', defaultMessage: 'City' } ) } 
                    required
                    invalidMsg={ intl.formatMessage({ description: 'Shop.Order.City.Required', defaultMessage: 'Please enter your city' }) }
                /> 
            </Col>
        </Row>
        <Countries 
            apiUrl={ shop.apiUrl + '/countries' }
            controlId='country'
            className='mb-3'
            placeholder={ intl.formatMessage( { description: 'Shop.Order.Country', defaultMessage: 'Country' } ) } 
            required
            invalidMsg={ intl.formatMessage({ description: 'Shop.Order.Country.Required', defaultMessage: 'Please select your country' }) }
        />
    </div>
}