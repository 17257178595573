import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Row, Col, Form } from 'react-bootstrap'

type CurrencySelectorProps<Currency extends string> = {
    className?: string
    initialCurrency?: Currency
    currencyNames: { [key in Currency]: string }
    setCurrency: ( currency: Currency ) => void
}

export const CurrencySelector = <Currency extends string>( { className, initialCurrency, currencyNames, setCurrency }: CurrencySelectorProps<Currency> ) => {

    const intl = useIntl()

    const defaultCurrency = (): Currency => {
        if( intl.locale === 'cs-CZ' && Object.keys( currencyNames ).includes( 'CZK' ) ) return 'CZK' as unknown as Currency
        else if( Object.keys( currencyNames ).includes( 'EUR' ) ) return 'EUR' as unknown as Currency
        return Object.keys( currencyNames )[ 0 ] as unknown as Currency
    }
    
    const [ currencySelect, setCurrencySelect ] = useState<Currency>( initialCurrency ?? defaultCurrency() )
    const [ currencySelected, setCurrencySelected ] = useState<boolean>( false )
    useEffect( () => {
        if( !currencySelected ) {
            const defCur = defaultCurrency()
            setCurrencySelect( defCur )
            setCurrency( defCur )
        }
    }, [ intl ])

    const onChange = ( ev: React.ChangeEvent<HTMLSelectElement> ) => {
        setCurrencySelect( ev.currentTarget.value as unknown as Currency )
        setCurrency( ev.currentTarget.value as unknown as Currency )
        setCurrencySelected( true )
    }

    return <Row className={ className }>
        <Col size={ 12 } className='text-end'>
            <div style={{ maxWidth:'10em', display:'inline-block' }}><Form.Select
                id='currency'
                value={ currencySelect }  
                onChange={ onChange }
                size="sm" 
                aria-label={ intl.formatMessage({ description: 'Shop.Order.CurrencySelector', defaultMessage: 'Select currency' }) }
            >
                { Object.entries( currencyNames ).map( p => <option key={ p[ 0 ] } value={ p[ 0 ] }>{ `${ p[ 1 ] } (${ p[ 0 ] })` }</option> ) }
            </Form.Select></div>
        </Col>
    </Row>

}