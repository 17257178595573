import React from 'react'
import { Carousel, Image, Container } from 'react-bootstrap'

import img1 from '../../../assets/img/carousel/1.jpg?format=webp&sizes[]=1000'
import img2 from '../../../assets/img/carousel/2.jpg?format=webp&sizes[]=1000'
import img4 from '../../../assets/img/carousel/4.jpg?format=webp&sizes[]=1000'
import img5 from '../../../assets/img/carousel/5.jpg?format=webp&sizes[]=1000'
import img6 from '../../../assets/img/carousel/6.jpg?format=webp&sizes[]=1000'
import img7 from '../../../assets/img/carousel/7.jpg?format=webp&sizes[]=1000'

import { About } from '../About'
import { Photos } from '../Photos'

export const Home = () => {

    return ( 
        <>
            <Carousel 
                variant='light'
                indicatorLabels={[
                    'cestovatelské fotoobrazy',
                    'nevídané úhly',
                    'jedinečné pohledy',
                    'dojemné momenty',
                    'na každém kroku dobrodružství',
                    'fotky s duší'
                ]}
            >
                <Carousel.Item>
                    <Image src={img1.src} fluid alt='cestovatelské fotoobrazy' className='w-100' />
                    <Carousel.Caption className="text-start">
                        <div className="line-1">cestovatelské</div>
                        <div className="line-2">FOTOOBRAZY</div>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <Image src={img2.src} fluid alt='nevídané úhly' className='w-100' />
                    <Carousel.Caption className="text-end">
                        <div className="line-1">nevídané</div>
                        <div className="line-2">ÚHLY</div>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <Image src={img4.src} fluid alt='jedinečné pohledy' className='w-100' />
                    <Carousel.Caption className="text-start">
                        <div className="line-1">jedinečné</div>
                        <div className="line-2">POHLEDY</div>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <Image src={img5.src} fluid alt='dojemné momenty' className='w-100' />
                    <Carousel.Caption className="text-end">
                        <div className="line-1">dojemné</div>
                        <div className="line-2">MOMENTY</div>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <Image src={img6.src} fluid alt='na každém kroku dobrodružství' className='w-100' />
                    <Carousel.Caption className="text-start">
                        <div className="line-1">na každém kroku</div>
                        <div className="line-2">DOBRODRUŽSTVÍ</div>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <Image src={img7.src} fluid alt='fotky s duší' className='w-100' />
                    <Carousel.Caption className="text-start">
                        <div className="line-1">fotky s</div>
                        <div className="line-2">DUŠÍ</div>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
            <Container className='px-0 px-md-5'>
                <About />
                <Photos maxCount={ 4 } />
            </Container>
        </>
    )   
}