export const Currencies = [ 'CZK', 'USD', 'EUR' ] as const;
export const CurrencyNames: { [ key in (typeof Currencies)[number] ]: string } = {
    'USD': '$',
    'EUR': '€',
    'CZK': 'Kč'
} as const;

export const PaymentTypes = [ 'BANK_TRANSFER' ] as const;

export const ContentSources = ['aws-cdn', 'aws-private', 'web'] as const;
export const ContentSourceConvert = new Map<(typeof ContentSources)[number], ( filename: string ) => string>([
    [ 'aws-cdn', ( fn: string ) => 'https://cdn.levicphoto.cz' + fn ],
    [ 'aws-private', ( fn: string ) => 'https://private.levicphoto.cz' + fn ],
    [ 'web', ( fn: string ) => fn ]
]);