import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { Container, Row, Col, Button, Image, Carousel, Form as BsForm } from 'react-bootstrap'

import { useFetch, useChange, useQueryString } from '../../../Common'
import { CheckBox, Form, FormContextType } from '../../../Forms'
import { useAuth } from '../../../Auth'
import { useNotifications } from '../../../Notifications'
import { useReview, useShop, Price, GetUser, VoucherEdit, CurrencySelector, getPackageIntlDetails, type PackgageIntlDetails, VariantSelector } from '../../../Shop'

import { ComponentHeading } from '../../components/ComponentHeading'
import { ComponentStatus } from '../../components/ComponentStatus'

import { Package as PackageModel, PackagePriceDetails, PaymentType, Voucher } from '../../../../../cz.levicphoto.api/src/_api-models/index'
import { CurrencyNames } from '../../../../../cz.levicphoto.api/src/_config/constants'

import { User } from '../../AppTypes'

export const Order = <Currency extends string, ContentSource extends string>() => {

    const intl = useIntl()

    const navigate = useNavigate()

    const { addNotification } = useNotifications()

    const shop = useShop<Currency, ContentSource>()

    const auth = useAuth()
    const user = auth.getUser() as User
    const review = useReview()

    const awsContentCoverter = shop.contentConverter.get( 'aws-cdn' as unknown as ContentSource )
    
    const params = useParams()
    const packageId = params.packageId ? parseInt( params.packageId ) : undefined
    useEffect( () => {
        if( ! packageId ) navigate( '/', { replace: true } )
        else review.setPackageId( packageId, packageId != review.packageId  )
        return () => {
            review.setPackageId( undefined, true )
        }
    }, [ packageId, navigate, review ] )

    const voucher = useQueryString( 'voucher' )

    // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents, @typescript-eslint/no-unsafe-assignment
    const [ pack, setPack ] = useState<PackageModel<Currency, ContentSource> | null>( null )
    const [ packIntlDetails, setPackIntlDetails ] = useState<PackgageIntlDetails | null>( null )
    const [ packPriceDetails, setPackPriceDetails ] = useState<PackagePriceDetails<Currency> | undefined>()
    const [ discountVoucher, setDiscountVoucher ] = useState<Voucher | undefined>()

    const [ variant, setVariant ] = useState<PackageModel<Currency, ContentSource> | null>( null )

    const emailRef = useRef<HTMLInputElement>( null )

    const errorFetchMsg = useMemo( () => intl.formatMessage({ description: 'LevicPhoto.Order.NoImageData', defaultMessage: 'Omlouvám se, ale něco se nezdařilo a nelze načíst data o obrázku. Prosím, zavolejte mi na +420 605914205.' }), [ intl ] )
    //const errorSendMsg = useMemo( () => intl.formatMessage({ description: 'LevicPhoto.Order.SubmitError', defaultMessage: 'Vaši objednávku se nepodařilo zaregistrovat. Zkuste to prosím později nebo mi zavolejte na +420 605914205.' }), [ intl ] )

    const fetchPackage = useFetch<PackageModel<Currency, ContentSource>[]>({
        // if no imageId is provided component will navigate elsewhere - here it is no option
        url: `${ shop.apiUrl }/shop/package/${ packageId }`,
        onSuccess: state => {
            if( !state.data || state.data.length === 0 ) {
                navigate( '/', { replace: true, state: { message: errorFetchMsg } } )
            } else {
                const pack = state.data[ 0 ]
                const pid = getPackageIntlDetails<Currency, ContentSource>( pack, intl.locale, shop.contentConverter )
                if( !pid ) navigate( '/', { replace: true, state: { message: errorFetchMsg } } )
                setPack( pack )
                setPackIntlDetails( pid ?? null )
                setPackPriceDetails( pack.ext_data?.prices?.find( p => p.currency === currency ) )
            }
        },
        onError: () => {
            addNotification({ variant: 'danger', category: 'order', content: errorFetchMsg }) 
        }
    })

    const [ currency, setCurrency ] = useState<Currency>( Object.keys( CurrencyNames )[ 0 ] as Currency )

    useEffect( () => {
        if( variant ) setPackPriceDetails( variant.ext_data?.prices?.find( p => p.currency === currency ) )
        else if( pack ) setPackPriceDetails( pack.ext_data?.prices?.find( p => p.currency === currency ) )
    }, [ currency, pack, variant ])

    useEffect( () => {
        if( pack ) {
            const pid = getPackageIntlDetails<Currency, ContentSource>( pack, intl.locale, shop.contentConverter as unknown as Map<ContentSource, ( fn: string ) => string> )
            setPackIntlDetails( pid ?? null )    
        }
    }, [ pack, intl ])

    const [ paymentType, setPaymentType ] = useState<PaymentType>( 'BANK_TRANSFER' )

    const sendOrder = useChange({ 
        url: `${ shop.apiUrl }/shop/order/new/register`,
        method: 'POST'
    })

    const onFinish = ( form: FormContextType ) => {
        const formUser = form.data
        sendOrder.run({ data: {
            ...formUser, 
            referrer: document.referrer,
            currency, 
            paymentType, 
            agreements: [
                /* only if not authenticated */ !formUser ? `oou-${ intl.locale }-202301.pdf` : undefined,
                `op-${ intl.locale }-202301.pdf`
            ].filter( Boolean ),    
            orderDetails: [ { packageId: variant?.packageId ?? packageId, isVoucher: false, voucherCode: discountVoucher?.token } ] 
        } })
    }

    useEffect( () => {
        emailRef.current?.focus()
    }, [] )

    
    return(<Container>
        <Row>
            <Col>
                <ComponentHeading title={ packIntlDetails?.name } />
            </Col>
        </Row>
        <Row>
            <Col md="7">
                <Carousel indicators={ false }>
                    { packIntlDetails?.gallery.map( ( img, idx ) => <Carousel.Item key={ idx }>
                        <Image src={ img.url } alt={ img.filename } fluid className='w-100 image-zoom'/>
                    </Carousel.Item> ) }
                </Carousel>
                <div className="p-md-4 p-0 py-4">
                    {/*Obraz je tištěn na prémiový fotopapír s vysokou gramáží a s vrstvou proti odrazu a je nalepen na 0,5cm desku. Dřevěný rám obrazu je v černé matné barvě bez dřevěné kresby. Bílý rámeček imitující paspartu má tloušťku 4cm.*/}
                    <FormattedMessage description="LevicPhoto.Order.Description" defaultMessage="The picture is printed on thick premium quality paper coated with special antireflexive layer and is layed on 0,5cm board. Wooden frame is painted with matt black and shows no wooden texture. White border imitating passe-partout is 4cm wide." />
                </div>
            </Col>
            <Col md="5" className="mt-4 mt-md-0">
                <h3>{ intl.formatMessage({ description: 'LevicPhoto.Order.Title', defaultMessage: 'Order photo print' } ) }</h3>
                <ComponentStatus 
                    error={ [ 'error', 'finish' ].includes( sendOrder.state.phase ) && !sendOrder.state.success ? sendOrder.state.message || '' : '' } 
                    loading={ fetchPackage.state.processing || sendOrder.state.processing } 
                />
                { sendOrder.state.phase === 'finish' && sendOrder.state.success && <div><FormattedMessage description="LevicPhoto.Order.Confirmation" defaultMessage="Thank you for your order. Before starting production I will confirm with you the details by e-mail or mobile. Have a nice day! Albert" /></div> }
                { pack && ( sendOrder.state.phase !== 'finish' || !sendOrder.state.success ) && <>
                    <Row>
                        <Col>{ packIntlDetails?.description ? packIntlDetails.description : '' }&nbsp;</Col>
                    </Row>
                    <CurrencySelector<Currency>
                        className='mb-2'
                        currencyNames={ CurrencyNames as { [ key in Currency ]: string } }
                        setCurrency={ setCurrency }
                    />
                    <Price<Currency> 
                        currency={ currency } 
                        currencyNames={ CurrencyNames as { [ key in Currency ]: string } } 
                        price={ packPriceDetails?.price } 
                        discount={ discountVoucher?.discount } 
                    />
                    <div className='my-3'>
                        <Form 
                            formName='image-order'
                            initialData={{
                                name: user?.name || '', 
                                email: user?.email || '', 
                                mobile: user?.mobile || '',
                                street: user?.street || '', 
                                zip: user?.zip || '', 
                                city: user?.city || '', 
                                country: user?.country || intl.locale === 'cs-CZ' ? 'CZ' : '', 
                                status: 'REGP', 
                                GDPRagreement: user ? true : false, 
                                purchaseAgreement: false, 
        
                            }}
                            onFinishCallback={ onFinish }
                        >
                            { packageId && <VariantSelector<Currency, ContentSource> basePackageId={ packageId } setVariantPackage={ setVariant } /> }
                            <GetUser requireMobile={ 'required' } refToFirst={ emailRef } regStatus='REGP' />
                            <Row className='mb-3 text-start'>
                                <Col>
                                    { /* show only if not authenticated */ !user && <CheckBox 
                                        controlId='GDPRagreement'
                                        type='checkbox' 
                                        required
                                    >
                                        <a href={ awsContentCoverter ? awsContentCoverter( `/oou-${ intl.locale }-202404.pdf` ) : '' } download target='_blank' rel="noreferrer">{ intl.formatMessage({ description: 'Shop.Order.GDPRAgreement', defaultMessage: 'I agree with privacy policy of this web' }) }</a> 
                                    </CheckBox> }
                                    <CheckBox 
                                        controlId='purchaseAgreement'
                                        type='checkbox' 
                                        required
                                    >
                                        <a href={ awsContentCoverter ? awsContentCoverter( `/op-${ intl.locale }-202404.pdf` ) : '' } download target='_blank' rel="noreferrer">{ intl.formatMessage({ description: 'App.Order.AgreePurchaseConditions', defaultMessage: 'I agree with purchase conditions' }) }</a> 
                                    </CheckBox>
                                </Col>
                            </Row>
                            <VoucherEdit packageId={ packageId ?? 0 } voucher={ voucher || '' } setDiscount={ setDiscountVoucher } />
                            <Row>
                                <Col className='py-3'>
                                    { /* <BsForm.Check
                                        type='radio'
                                        name='paymentType'
                                        id='PAYU'
                                        label={ <>
                                            <FormattedMessage description='App.Order.PaymentType.OnlinePayU' defaultMessage='Online payment using PayU' />
                                            <br />
                                            <img src={ MasterCard.src } height='30' alt='MasterCard' />
                                            <img src={ Visa.src } height='30' alt='Visa' />
                                            <img src={ KB.src } height='20' alt='Komerční banka a.s.' />&nbsp;
                                            <img src={ CSAS } height='30' alt='Česká spořitelna a.s.' />
                                            <img src={ CSOB.src } height='30' alt='ČSOB a.s.' />
                                            <img src={ Fio.src } height='30' alt='Fio banka a.s.' />
                                        </> }
                                        checked={ paymentType === 'PAYU' }
                                        onChange={ ( ev: React.ChangeEvent<HTMLInputElement> ) => { if( ev.currentTarget.checked ) setPaymentType( 'PAYU' ) } }
                                    /> */ }
                                    <BsForm.Check
                                        type='radio'
                                        name='paymentType'
                                        id='BANK_TRANSFER'
                                        label={ <FormattedMessage description='App.Order.PaymentType.BankTransfer' defaultMessage='Bank Transfer' /> }
                                        checked={ paymentType === 'BANK_TRANSFER' }
                                        onChange={ ( ev: React.ChangeEvent<HTMLInputElement> ) => { if( ev.currentTarget.checked ) setPaymentType( 'BANK_TRANSFER' ) } }
                                    />
                                    { /* <BsForm.Check
                                        type='radio'
                                        name='paymentType'
                                        id='PAYPAL'
                                        label={ <FormattedMessage description='App.Order.PaymentType.PayPal' defaultMessage='PayPal' /> }
                                        checked={ paymentType === 'PAYPAL' }
                                        onChange={ ( ev: React.ChangeEvent<HTMLInputElement> ) => { if( ev.currentTarget.checked ) setPaymentType( 'PAYPAL' ) } }
                                    /> */ }
                                </Col>
                            </Row>
                            <Button type='submit' variant='info'>
                                <FormattedMessage description='LevicPhoto.Order.Submit' defaultMessage='Confirm order' />
                            </Button>
                        </Form>
                    </div>
                </> }
            </Col>
        </Row>
    </Container>)
}