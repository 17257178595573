import React, { useCallback, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Col, Row, Form as BsForm } from 'react-bootstrap'

import { useFetch } from '../../../Common'

import { useShop } from '../../hooks/useShop'

import { Voucher } from '../../../../../cz.levicphoto.api/src/_api-models'

type VoucherEditProps = {
    packageId: number
    voucher?: string
    setDiscount: ( voucher: Voucher | undefined ) => void
}

export const VoucherEdit = <Currency extends string, ContentSource extends string>({ packageId, voucher, setDiscount }: VoucherEditProps ) => {

    const intl = useIntl()

    const shop = useShop<Currency, ContentSource>()

    const [ voucherCode, setVoucherNo ] = useState( voucher || '' )
    const [ voucherValid, setVoucherValid ] = useState<boolean | undefined>( undefined )
    const [ voucherInvalidMsg, setVoucherInvalidMSg ] = useState<string| undefined>( undefined )

    // this must be outside of hook as far as change in price nedd to be reflected
    const checkVoucherValidRequest = useFetch<Voucher[]>( {
        url: `${ shop.apiUrl }/shop/voucher`,
        noAutoRun: true,
        onError: ( state ) =>{
            setDiscount( undefined )
            setVoucherValid( false )
            setVoucherInvalidMSg( state.errorCode )
        }
    }, [] )

    const checkVoucherValid = useCallback( ( voucherCode: string ) => {
        checkVoucherValidRequest.run({ 
            url: `${ shop.apiUrl }/shop/voucher/${ voucherCode }/${ packageId || 'XXXXX' }`,
            onSuccess: ( state ) => {
                if( state.data && state.data.length === 1 ) {
                    setDiscount( state.data[ 0 ] )
                    setVoucherValid( true )
                }
                else {
                    setDiscount( undefined )
                    setVoucherValid( false )
                }
            }
        })
    }, [ packageId ] )

    useEffect( () => {
        if( voucher ) checkVoucherValid( voucher )
    }, [])

    return <Row className='py-1'>
        <Col><FormattedMessage description='Shop.Order.EnteruVoucherCodeLabel' defaultMessage='Enter your voucher code' /></Col>
        <BsForm.Group as={ Col } className="text-end">
            <BsForm.Control 
                id='voucherCode'
                type='text'
                size="sm" 
                pattern={ voucherCode !== '' && typeof voucherValid === 'boolean' && !voucherValid ? voucherCode + 'x' : '[A-Za-z0-9_]*' }
                className='text-center'
                style={{
                    maxWidth:'10em', 
                    display:'inline-block'
                }}
                value={ voucherCode }
                placeholder={ intl.formatMessage({ description: 'Shop.Order.EnterVoucherCodePlaceholder', defaultMessage: 'voucher code' }) }
                onChange={ ( ev ) => {
                    setVoucherNo( ev.currentTarget.value ) 
                    setVoucherValid( undefined ) // reset validation if typing started
                }}
                onBlur={ ( ev ) => {
                    const code = ev.currentTarget.value
                    if( code !== '' ) checkVoucherValid( code )
                    else {
                        setDiscount( undefined )
                        setVoucherValid( undefined )
                    }
                }}
                //isValid={ voucherCode !== '' && typeof voucherValid === 'boolean' && voucherValid }
                isInvalid={ voucherCode !== '' && typeof voucherValid === 'boolean' && !voucherValid }
            />
            <BsForm.Control.Feedback type="invalid">{
                voucherInvalidMsg === 'VoucherAPI.get.VoucherNotForThisProduct' ? <FormattedMessage description='Shop.Order.VoucherNotForThisProduct' defaultMessage='voucher for other product' />
                    : voucherInvalidMsg === 'VoucherAPI.get.VoucherAlreadyUsed' ? <FormattedMessage description='Shop.Order.VoucherAlreadyUsed' defaultMessage='voucher already used' />
                        : voucherInvalidMsg === 'VoucherAPI.get.VoucherExpired' ? <FormattedMessage description='Shop.Order.VoucherExpired' defaultMessage='voucher expired' />
                            : <FormattedMessage description='Shop.Order.VoucherValiadtionError' defaultMessage='voucher code not found' />
            }</BsForm.Control.Feedback>
        </BsForm.Group>
    </Row>
}