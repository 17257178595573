import React, { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { Row, Col, Form } from 'react-bootstrap'

import { useFetch } from '../../../Common'
import { useNotifications } from '../../../Notifications'

import { useShop } from '../../hooks/useShop'
import { getPackageIntlDetails } from '../../utils'

import { Package as PackageModel } from '../../../../../cz.levicphoto.api/src/_api-models/index'

type VariantSelectorProps<Currency extends string, ContentSource extends string> = {
    className?: string
    basePackageId: number
    initialVariantPackageId?: number
    setVariantPackage: ( variantPackage: PackageModel<Currency, ContentSource> | null ) => void
}

export const VariantSelector = <Currency extends string, ContentSource extends string>( { className, basePackageId, initialVariantPackageId, setVariantPackage }: VariantSelectorProps<Currency, ContentSource> ) => {

    const intl = useIntl()

    const errorFetchMsg = intl.formatMessage({ description: 'Shop.Order.VariantsSelector.ErrorFetch', defaultMessage: 'Error fetching variants' })

    const { addNotification } = useNotifications()

    const shop = useShop()

    const [ variants, setVariants ] = useState<PackageModel<Currency, ContentSource>[] | null>( null )
    const [ varinatPackageIdSelect, setVariantPackageIdSelect ] = useState<number>( initialVariantPackageId ?? 0 )
    
    const fetchVariants = useFetch<PackageModel<Currency, ContentSource>[]>({
        url: `${ shop.apiUrl }/shop/package/variants/${ basePackageId }?order=${ encodeURIComponent( JSON.stringify( { packageId:'ASC' } ) ) }`,
        onSuccess: state => {
            if( !state.data ) {
                addNotification({ variant: 'danger', category: 'order', content: errorFetchMsg })
            } else {
                setVariants( state.data )
                if( state.data.length < 1 ) setVariantPackage( null )
                else {
                    setVariantPackageIdSelect( state.data[ 0 ].packageId )
                    setVariantPackage( state.data[ 0 ] )
                }
            }
        },
        onError: () => {
            addNotification({ variant: 'danger', category: 'order', content: errorFetchMsg }) 
        }
    })

    useEffect( () => {
        fetchVariants.run({})
    }, [ basePackageId ] )

    const onChange = ( ev: React.ChangeEvent<HTMLSelectElement> ) => {
        const packageId = parseInt( ev.currentTarget.value )
        setVariantPackageIdSelect( packageId )
        setVariantPackage( variants?.find( v => v.packageId === packageId ) ?? null )
    }

    return <>
    { variants && variants.length > 0 && <Row className={ [ 'variant-selector', 'mb-3', className ].join(' ')  }>
        <Col size={ 12 } className='text-end'>
            <div style={{ minWidth:'50%', display:'inline-block' }}><Form.Select
                id='variants'
                value={ varinatPackageIdSelect }  
                onChange={ onChange }
                size="sm" 
                aria-label={ intl.formatMessage({ description: 'Shop.Order.VariantsSelector', defaultMessage: 'Select variant' }) }
            >
                { variants.map( v => <option key={ v.packageId } value={ v.packageId }>{ getPackageIntlDetails( v, intl.locale, shop.contentConverter )?.name || 'NA' }</option> ) }
            </Form.Select></div>
        </Col>
    </Row> }
</>
}