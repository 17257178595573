import React, { createContext, useCallback, useState } from 'react'

import { ReviewContextType, ReviewShowStatus } from '../types'

type ReviewContextProviderProps = {
    packageId?: number
    showStatus: ReviewShowStatus
}

const dummyContext: ReviewContextType = {
    packageId: undefined,
    setPackageId: () => {},
    showStatus: 'visible',
    setShowStatus: () => {},
    reviewBtnSuffix: undefined
}

export const ReviewContext = createContext<ReviewContextType>( dummyContext )

export const ReviewProvider = ( props: React.PropsWithChildren<ReviewContextProviderProps> ) => {
    const [ packageId, setPackageId ] = useState<number | undefined>( props.packageId )
    const [ showStatus, setShowStatus ] = useState<ReviewShowStatus>( props.showStatus )

    const setPackageWithShow = useCallback( ( _packageId: number | undefined, show: boolean ) => {
        if( _packageId !== packageId ) {
            setPackageId( _packageId )
            if( show ) setShowStatus( 'visible' )
        }
    }, [ setPackageId, setShowStatus, packageId ] )
    return <ReviewContext.Provider value={{ packageId, setPackageId: setPackageWithShow, showStatus, setShowStatus }}>{ props.children }</ReviewContext.Provider>
}
