import React from 'react'
import { Container } from 'react-bootstrap'

import { Home } from './views/Home'
import { Photos } from './views/Photos'
import { Order } from './views/Order'
import { Login } from '../Auth'

import AUTH_ROUTES from '../Auth/routes'
import { Route } from '../Common'

export const ROUTES: Route[] = [
    {
        key: 'home',
        route: '/',
        label: 'domů',
        element: <Home />,
        isAuthenticated: false,
        prerender: {
            imageUrl: 'htts://cdn.levicphoto.cz/imgs/logo-web-02-bila.png',
            langSpecifics: {
                'cs-CZ': {
                    pageTitle: 'africa@levicphoto.cz',
                    pageDescription: 'Africa 2023'
                },
                'en-US': {
                    pageTitle: 'africa@levicphoto.cz',
                    pageDescription: 'Africa 2023'
                }
            }
        }
    },
    {
        key: 'photos-en',
        route: '/photos',
        label: 'Objednávka',
        element: <Photos />
    },
    {
        key: 'photos-cs',
        route: '/fotoobrazy',
        label: 'Objednávka',
        element: <Photos />
    },
    {
        key: 'order-en',
        route: '/order/:packageId',
        label: 'Order',
        element: <Order />
    },
    {
        key: 'order-cs',
        route: '/objednávka/:packageId',
        label: 'Objednávka',
        element: <Order />
    },
    {
        key: 'login',
        route: [ '/log-in', '/prihlasit' ],
        label: 'přihlásit',
        element: <Container><Login /></Container>,
        isAuthenticated: false,
        prerender: {
            langSpecifics: {
                'cs-CZ': {
                    pageTitle: 'Přihlášení pro uživatele'
                },
                'en-US': {
                    pageTitle: 'Log-in for subscribers'
                }                
            }
        }
    },
    ...AUTH_ROUTES
]
