import React, { useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate, Link } from 'react-router-dom'
import { Container, Row, Col, Card } from 'react-bootstrap'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'

import { useFetch } from '../../../Common'
import { useNotifications } from '../../../Notifications'
import { useShop, getPackageIntlDetails } from '../../../Shop'

import { ComponentHeading } from '../../components/ComponentHeading'
import { ComponentStatus } from '../../components/ComponentStatus'

import { Package as PackageModel } from '../../../../../cz.levicphoto.api/src/_api-models/index'

type PhotosProps = {
    maxCount?: number
}

export const Photos = <Currency extends string, ContentSource extends string>({ maxCount }: PhotosProps ) => {

    const intl = useIntl()

    const navigate = useNavigate()
    
    const { addNotification } = useNotifications()
    const shop = useShop<Currency, ContentSource>()
    
    const errorFetchMsg = useMemo( () => intl.formatMessage({ description: 'LevicPhoto.Photos.NoPackages', defaultMessage: 'Omlouvám se, ale něco se nezdařilo a nelze načíst informace o produktech. Prosím, zavolejte mi na +420 605914205.' }), [ intl ] )

    const [ pkgs, setPkgs ] = useState<PackageModel<Currency, ContentSource>[] | null>( null )

    const fetchPackages = useFetch<PackageModel<Currency, ContentSource>[]>({
        // if no imageId is provided component will navigate elsewhere - here it is no option        
        url: `${ shop.apiUrl }/shop/package`,
        onSuccess: state => {
            if( !state.data || state.data.length < 1 ) {
                addNotification({ variant: 'danger', category: 'order', content: errorFetchMsg }) 
                navigate( '/', { replace: true, state: { message: errorFetchMsg } } )
            } else {
                setPkgs( maxCount ? state.data.filter( ( img, idx ) => idx < maxCount ) : state.data )
            }
        },
        onError: () => {
            addNotification({ variant: 'danger', category: 'order', content: errorFetchMsg }) 
        }
    })

    return (<Container className="photos">
        <ComponentHeading title={ intl.formatMessage({ description: 'LevicPhoto.Photos.Heading', defaultMessage: 'Photos' }) } />
        <ComponentStatus 
            error={ fetchPackages.state.phase === 'error' ? fetchPackages.state.message || '' : '' } 
            loading={ fetchPackages.state.processing } 
        />
        { pkgs && <ResponsiveMasonry columnsCountBreakPoints={{ /*576: 1,*/ 768: 1, 992: 2 }}><Masonry>
            { pkgs.map( pkg => {
                const pid = getPackageIntlDetails<Currency, ContentSource>( pkg, intl.locale, shop.contentConverter )
                const price = pkg.ext_data?.prices.find( p => p.currency == ( intl.locale == 'cs-CZ' ? 'CZK' : 'EUR' ) )
                let priceTxt = ''
                if( price ) {
                    priceTxt = ( price.currency != 'CZK' ? '€ ' : '' ) 
                        + price.price.toLocaleString( intl.locale )
                        + ( price.currency == 'CZK' ? ' Kč' : '' ) 
                }
                if( !pid ) return null
                return pid.thumb ? 
                    <Link key={ pkg.packageId } to={ '/order/' + pkg.packageId }  className='p-1'>
                        <Card className="border-0">
                            <Card.Img variant="top" src={ pid.thumb.url } alt={ pid.name } />
                            <Card.Body>
                                <Card.Title>{ pid.name }</Card.Title>
                                <Card.Text><FormattedMessage description="LevicPhoto.Order.From" defaultMessage="from" />&nbsp;{ priceTxt }</Card.Text>
                            </Card.Body>
                        </Card>
                    </Link>
                    : null
            } ).filter( Boolean ) }
        </Masonry></ResponsiveMasonry> }
        { maxCount && <Row>
            <Col align="center" className="my-4"><Link to="photos" className="btn btn-info"><FormattedMessage description="LevicPhoto.ShowAll" defaultMessage="Show all" /></Link></Col>
        </Row> }
    </Container>)
}