import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
//import { QueryClient, QueryClientProvider } from 'react-query'
import { Container } from 'react-bootstrap'

import { NotificationStack, NotificationOnPageLoad } from '../../../Notifications'
import { AuthContextProvider, AuthRoutes, AuthServiceAPI } from '../../../Auth'
import { ToTopBtn, ToTopOnRoute } from '../../../Common'

import { API_URL, BASENAME } from '../../AppConfig'
import { ROUTES } from '../../AppRoutes'
import { User } from '../../AppTypes'

import { AppHeader } from '../AppHeader'
import { AppFooter } from '../AppFooter'
import { View } from '../View'
import { Home } from '../../views/Home'
import { AppContext } from '../AppContext'
import { ComponentHeading } from '../ComponentHeading'

//const queryClient = new QueryClient()

const authService = AuthServiceAPI<User>( API_URL )

export const AppContent = () => {
    return <>
        <NotificationOnPageLoad />
        <AuthContextProvider 
            authService={ authService } 
            viewWrapper={ View }
            loginHome='/'
            pageHeading={ ComponentHeading }
        >
            <Container fluid>
                <ToTopOnRoute />
                <ToTopBtn variant='info' />
                <AppHeader />                
                <AuthRoutes routes={ ROUTES } defaultRoute={{ element: <Home />, isAuthenticated: false }} />
                <AppFooter />
                <NotificationStack />
            </Container>
        </AuthContextProvider>
    </>
}

export const App = () => <AppContext><Router basename={ BASENAME }><AppContent /></Router></AppContext>