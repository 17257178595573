import React, { createContext } from 'react'

import { ComponentWithChildrenProps, AppStatusComponentProps } from '../../Common'
import { ContentConverter, ContentConverterMap, ShopContextType } from '../types'

const dummyContext = <ConetentSource extends string>(): ShopContextType<ConetentSource> => ({
    apiUrl: '',
    componentHeading: ( props ) => <div title={ props.title }>{ props.children }</div>,
    componentStatus: ()=> <></>,
    viewWrapper: ( props ) => <>{ props.children }</>,
    loginHome: '',
    contentConverter: new Map<ConetentSource, ContentConverter>()
})

type ShopContextProviderProps<ConetentSource extends string> = {
    apiUrl: string
    componentHeading: React.ElementType<ComponentWithChildrenProps & {title?: string}>
    componentStatus: React.ElementType<AppStatusComponentProps>
    viewWrapper?: React.FC<ComponentWithChildrenProps>
    loginHome: string
    contentConverter: ContentConverterMap<ConetentSource>
}

export const ShopContext = createContext<ShopContextType<string>>( dummyContext() )

export const ShopProvider = <ConetentSource extends string>( { apiUrl, componentHeading, componentStatus, viewWrapper, loginHome, contentConverter, children }: React.PropsWithChildren<ShopContextProviderProps<ConetentSource>> ) => {

    return <ShopContext.Provider
        value={{
            apiUrl,
            componentHeading,
            componentStatus,
            viewWrapper,
            loginHome,
            contentConverter: (contentConverter as ContentConverterMap<ConetentSource>)
        }}
    >{ children }</ShopContext.Provider>
}