import React from 'react'

const animationContainer: React.CSSProperties = {
    width: '102%',
    height: '102%',
    position: 'absolute',
    display: 'flex',
    margin: '-1%',
    background: '#99776255'
}

const spinner: React.CSSProperties = {
    margin: 'auto',
    animation: 'rotation 4s linear infinite',
}

type LoadingType = {
    show?: boolean
}

export const Loading = ( { show }: LoadingType ) => {

    return ( typeof show === 'undefined' || show ) ? <div style={ animationContainer }>
        <div style={ spinner }>A</div>
    </div> : <></>
}