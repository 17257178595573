import { ISnippetsParams } from '@elgorditosalsero/react-gtm-hook/dist/models/GoogleTagManager'

import English from '../../translation/compiled/en-US.json'
import Czech from '../../translation/compiled/cs-CZ.json'

import { Lang } from '../I18N'
import { CookiePurposeType } from '../Common/components/CookieConsent'

//import { ContentSources } from '../../../cz.levicphoto.api/src/_api-models'

import logo_all from '../assets/img/logo-web-02-bila.png?format=webp'

export const BASENAME = '/'

export const GTM_PARAMS: ISnippetsParams = {
    id: 'GTM-NGQXZWPS',
    ...ENV_GTM_PARAMS
}

export const WEB_URL = 'https://www.levicphoto.cz'
export const WEB_SITE_NAME = 'www.levicphoto.cz'
export const WEB_LOGO = [
    { isoCode: 'cs-CZ', logo: logo_all.src },
    { isoCode: 'en-US', logo: logo_all.src }
]

export const API_URL = ENV_API_URL

export const LANG_LIST: Lang[] = [
    {
        isoCode: 'cs-CZ',
        label: 'CS',
        messages: Czech
    },
    {
        isoCode: 'en-US',
        label: 'EN',
        messages: English
    },
]

/*
export const CONTENT_SOURCES: { [ key in ContentSources ]: string } = {
    'aws-cdn': 'https://cdn.levicphoto.cz',
    'aws-private': 'https://private.leviphoto.cz',
    'web': ''
}
*/

export const CookieConsentSupportedTypes: CookiePurposeType[] = [ 'necessary', 'preferences', 'statistics' ]
export const CookieConsentDefaultConsent = { necessary: true, preferences: false, statistics: false }