import { Package as PackageModel } from '../../../../cz.levicphoto.api/src/_api-models/index'
import { ContentConverterMap } from '../types'

export type PackageImageSource = {
    url: string
    filename: string
}
export type PackgageIntlDetails = {
    locale: string
    name: string
    description?: string
    thumb?: PackageImageSource
    gallery: PackageImageSource[]
}

export const getPackageIntlDetails = <Currency extends string, ContentSource extends string>( item: PackageModel<Currency, ContentSource>, locale: string, contentSourceConverterMap: ContentConverterMap<ContentSource> ): PackgageIntlDetails | undefined => {

    let pd = item.ext_data?.intl.find( id => id.locale == locale )
    if( !pd ) pd = item.ext_data?.intl[ 0 ]
    if( !pd ) throw new Error( 'No intl data found for package' )

    let thumbUrl: string | undefined = undefined, 
        thumbFilename: string | undefined = undefined,
        gallery: PackageImageSource[] = []
    if( pd?.gallery && pd.gallery.length > 0 ) {
        const thumb = pd.gallery[ 0 ]
        const thumbConverter = contentSourceConverterMap.get( thumb.source )
        thumbUrl = thumbConverter ? thumbConverter( thumb.filename ) : undefined
        thumbFilename = thumb.filename
        gallery = pd.gallery.map( img => { 
            const converter = contentSourceConverterMap.get( img.source )
            return { url: converter ? converter( img.filename ) : img.filename, filename: img.filename }
        })
    }
    return {
        locale: pd.locale,
        name: pd.name,
        description: pd.description,
        thumb: thumbUrl && thumbFilename ? { url: thumbUrl, filename: thumbFilename } : undefined,
        gallery
    }
}
