import React from 'react'
import classNames from 'classnames'

interface ComponentHeadingProps {
    title?: string
    className?: string
    style?: React.CSSProperties
    children?: React.ReactNode
}

export const ComponentHeading = ( { title, className, style, children }: ComponentHeadingProps ) => {
    const cls = React.useMemo( () => classNames( 'component-heading', className ), [ className ] )
    return (
        <div className={ cls } style={ style } title={ title }>
            <div className="ugb-inner-block">
                <div className="ugb-block-content">
                    <h2 className="ugb-heading__title">{title ?? children}</h2>
                </div>
            </div>
        </div>
    )
}
