import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Container, Row, Col } from 'react-bootstrap'
import { BsFacebook, BsInstagram, BsYoutube } from 'react-icons/bs'

import { CookieDetail, CookieIntlMessages } from '../../../Common'
import { Review } from '../../../Shop'

import { CookieConsentSupportedTypes } from '../../AppConfig'
import { MailTo } from '../MailTo'

export const AppFooter = () => {

    const intl = useIntl()

    const [ showCookieDetail, setShowCookieDetail ] = useState( false )
    const hideCookieDetail = () => setShowCookieDetail( false )

    return <Container className='app-footer'>
        <CookieDetail supportedCookieTypes={ CookieConsentSupportedTypes } show={ showCookieDetail } hide={ hideCookieDetail } />
        <Row className='mt-5 mb-2 text-white bg-brown'>
            <Col xs={{ span: 12, order: 2 }} md={{ span: 6, order: 1 }} className="p-3">
                <h3><FormattedMessage description='App.Footer.Contact.Heading' defaultMessage='Contact' /></h3>
                <div>Albert&nbsp;Levíček</div>
                <div>Tel.: +420&nbsp;605914205</div>
                <div><MailTo /></div>
                <div className='fs-5 app-footer-social-buttons'>
                    <a target='_blank' rel='noreferrer' href='http://www.instagram.com/levicphoto' className='link-light'><BsInstagram /></a>
                    <a target='_blank' rel='noreferrer' href='https://www.facebook.com/profile.php?id=100069651606958' className='link-light'><BsFacebook /></a>
                    <a target='_blank' rel='noreferrer' href='https://www.youtube.com/channel/UC1yOLeOsdDSa8q2AZcYjRWQ/featured' className='link-light'><BsYoutube /></a>
                </div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div style={{ fontSize: '80%' }}>
                    { /* eslint-disable-next-line jsx-a11y/anchor-is-valid */ }
                    <a href='#' className='link-light' onClick={ ( ev ) => { ev.preventDefault(); setShowCookieDetail( true ) }}>{ intl.formatMessage( CookieIntlMessages.customizeLink  )}</a>
                </div>
            </Col>
            <Col xs={{ span: 12, order: 1 }} md={{ span: 6, order: 2 }} className="p-3">
                <Review />
            </Col>
        </Row>
    </Container>
}