import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { Row, Col } from 'react-bootstrap'

type PriceProps<Currency extends string> = {
    className?: string
    currency: Currency
    currencyNames: { [key in Currency]: string }
    price?: number
    discount?: number
    showDiscountAlways?: boolean
}

export const Price = <Currency extends string>( { className, currency, currencyNames, price, discount, showDiscountAlways }: PriceProps<Currency> ) => {

    const intl = useIntl()

    const calcDiscount = () => {
        return !price || !discount
            ? 0
            : discount <= 1 
                ? Math.floor( discount * price )
                : Math.max( discount, price )
    }

    const calcPrice = () => {
        return price ? ( price - calcDiscount() ) : 0
    }

    const formatPrice = ( price: number ) => 
        ( currency !== 'CZK' ? currencyNames[ currency ] : '' ) + price.toLocaleString( intl.locale ) + ( currency === 'CZK' ? ' Kč' : '' )

    return <div className={ className }>
        { typeof price !== 'undefined' && <>
            <Row>
                <Col>
                    <FormattedMessage description='Shop.Order.Price' defaultMessage='Price' />:
                </Col>
                <Col className='text-end'>
                    { formatPrice( price ) }
                </Col>
            </Row>
            { ( typeof discount !== 'undefined' || showDiscountAlways ) && <>
                <Row>
                    <Col>
                        <FormattedMessage description='Shop.Order.Discount' defaultMessage='Discount' />:
                    </Col>
                    <Col className='text-end'>
                        -&nbsp;{ formatPrice( calcDiscount() ) }
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormattedMessage description='Shop.Order.PriceAfterDiscount' defaultMessage='Price after discount' />:
                    </Col>
                    <Col className='text-end'>
                        { formatPrice( calcPrice() ) }
                    </Col>
                </Row>
            </> }
        </> }
    </div>
}