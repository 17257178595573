import React, { forwardRef, useCallback, useRef } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, Col, Row } from 'react-bootstrap'

import { AsyncHttpRequestState, ComponentWithChildrenProps, useChange } from '../../../Common'
import { useNotifications } from '../../../Notifications'
import { useAuth, User } from '../../../Auth'
import { Form, Text, FormContextType, CheckBox, TextArea } from '../../../Forms'

import { useShop } from '../../hooks/useShop'
import { useReview } from '../../hooks/useReview'

type ReviewProps = {
    sendBtnSuffix?: string | React.ReactNode
    componentHeading?: React.ElementType<ComponentWithChildrenProps & {title?: string}>
}

export const Review = forwardRef<HTMLInputElement, ReviewProps>( ( { sendBtnSuffix, componentHeading }, ref ) => {

    const intl = useIntl()

    const shop = useShop()
    const review = useReview()

    const auth = useAuth<User>()
    const user = auth.getUser() as User

    const awsContentConvert = shop.contentConverter.get( 'aws-cdn' )
    
    const { addNotification, removeAllNotifications } = useNotifications()

    const formRef = useRef<HTMLFormElement>( null )

    const onSuccess = useCallback( ( state: AsyncHttpRequestState<unknown, unknown> ) => {
        if( state.success ) {
            formRef.current?.reset()
            addNotification({ variant: 'success', category: 'app', content: intl.formatMessage({ description: 'Shop.Review.Send.Success', defaultMessage: 'Your review was registered. Thank you!!!' }), timeout: 6000 })
            review.setShowStatus( 'finished' )
        } else {
            addNotification({ variant: 'danger', category: 'app', content: intl.formatMessage( { description: 'Shop.Review.Send.Error', defaultMessage: 'There was error storign your review. Pleas, try it once more.' } ) })
        }    
    }, [ review.setShowStatus, addNotification, intl ] )

    const onError = useCallback( ( state: AsyncHttpRequestState<unknown, unknown> ) => {
        addNotification({ variant: 'danger', category: 'app', content: intl.formatMessage( { description: 'Shop.Review.Send.Error', defaultMessage: 'There was error storign your review. Pleas, try it once more.' } ) })
    }, [ addNotification, intl ] )

    const reviewRequest = useChange({ 
        url: `${ shop.apiUrl }/shop/review/send`, 
        method: 'POST',
        label: 'review',
        onSuccess,
        onError
    })

    const onFinish = useCallback( ( form: FormContextType ) => {
        removeAllNotifications( 'auth ')
        const user = form.data
        reviewRequest.run({ data: { 
            ...user,
            packageId: review.packageId,
            referrer: document.referrer,
            agreements: [
                `oou-${ intl.locale }-202301.pdf`
            ]
        } })
    }, [ review.packageId, reviewRequest, removeAllNotifications, intl ] )

    return(<div className='position-relative'>
        { review.showStatus == 'visible' && <>
            <shop.componentHeading><FormattedMessage description='Shop.Review.Heading' defaultMessage='Leave me a review' /></shop.componentHeading>
            <shop.componentStatus loading={ reviewRequest.state.processing } />
            <Form 
                ref={ formRef }
                formName='review'
                initialData={ 
                    { name: user?.name || '', email: user?.email || '', review: '', status: 'REGP', GDPRagreement: auth.isAuthenticated }
                }
                onFinishCallback={ onFinish }
            >
                <Text 
                    ref={ ref }
                    controlId='name' 
                    className='mb-3'
                    placeholder={ intl.formatMessage({ description: 'auth.name', defaultMessage: 'Name' }) }
                    disabled={ auth.isAuthenticated }
                    autocomplete
                    required
                    invalidMsg={ intl.formatMessage({ description: 'auth.name-required', defaultMessage: 'Please input your name!' }) }
                />
                <Text 
                    controlId='email' 
                    type='email'
                    className='mb-3'
                    placeholder={ intl.formatMessage({ description: 'auth.email', defaultMessage: 'Email' }) }
                    disabled={ auth.isAuthenticated }
                    autocomplete
                    required
                    invalidMsg={ intl.formatMessage({ description: 'auth.email-required', defaultMessage: 'Please input your Email!' }) }
                />
                <TextArea 
                    controlId='review' 
                    className='mb-3'
                    placeholder={ intl.formatMessage({ description: 'Shop.Review.Message', defaultMessage: 'message' }) }
                    autocomplete
                    required
                    invalidMsg={ intl.formatMessage({ description: 'Shop.Review.Message.Required', defaultMessage: 'Please input your review!' }) }
                />
                { !auth.isAuthenticated && <Row className='mb-3 text-start'>
                    <Col>
                        <CheckBox 
                            controlId='GDPRagreement'
                            type='checkbox' 
                            required
                        >
                            <a href={ awsContentConvert ? awsContentConvert( `/oou-${ intl.locale }-202403.pdf` ) : '' } download target='_blank' rel="noreferrer">{ intl.formatMessage({ description: 'Shop.Order.GDPRAgreement', defaultMessage: 'I agree with privacy policy of this web' }) }</a> 
                        </CheckBox>
                    </Col>
                </Row> }
                <Row className='mb-3'>
                    <Col>
                        <Button type='submit' className='review-form-button'>
                            <><FormattedMessage description='Review.Send.Btn' defaultMessage='Send' /> { sendBtnSuffix || '' }</>
                        </Button>
                    </Col>
                </Row>
            </Form>
        </> }
    </div>)
} )