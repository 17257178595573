import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Row, Col, Card, Image } from 'react-bootstrap'

import { ComponentHeading } from '../../components/ComponentHeading'

import profilovka from '../../../assets/img/profilovka-0027.jpg.webp'

export const About = () => {
    const intl = useIntl()

    const title = intl.formatMessage({ description: 'App.AboutMe.Title', defaultMessage: 'Kdo jsem' })

    return (<section className="about">
        <ComponentHeading title={ title } />
        <Card bg="secondary" border='0' className="rounded">
            <Row className="g-4">
                <Col md="7" className="p-5 caption">
                    <strong><FormattedMessage description="App.AboutMe.Hi" defaultMessage="Ahoj!" /></strong>&nbsp;
                    <FormattedMessage description="App.AboutMe.Text" defaultMessage="Jmenuji se Albert. Pocházím z Berounska, ale nejšťastnější jsem na Šumavě, tréninku nebo v posteli s dobrou knihou nebo filmem (Tak trochu introvert :D). Miluju cestování, focení (nečekaně...), rap a pořádně si máknout. Tenhle web vznikl jako online výstava mých oblíbených fotek, které si od teď může kdokoli z vás lusknutím prstů objednat k sobě do obýváku!" />
                </Col>
                <Col md="5"><Image src={ profilovka } fluid className="rounded" alt="Albert Levíček foto" /></Col>
            </Row>
        </Card>
    </section>)
}