import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Container, Nav, NavDropdown, Navbar, Offcanvas } from 'react-bootstrap'

import { useI18N } from '../../../I18N'
import { LoginMsg, LogoutMsg, ProfileMsg, User, useAuth } from '../../../Auth'

import { useLogo } from '../../hooks/useLogo'
import { LANG_LIST } from '../../AppConfig'
import { BsFillPersonFill } from 'react-icons/bs'

export const AppHeader = () => {

    const intl = useIntl()
    const i18n = useI18N()

    const navigate = useNavigate()

    const loginMenuTitle = useMemo( () => intl.formatMessage( LoginMsg ), [ intl ] )
    const logoutMenuLabel = useMemo( () => intl.formatMessage( LogoutMsg ), [ intl ] )
    const profileMenuLabel = useMemo( () => intl.formatMessage( ProfileMsg ), [ intl ] )
    const myContentMenuLabel = useMemo( () => intl.formatMessage({ description: 'AppHeader.Menu.MyContentLabel', defaultMessage: 'My content' }), [ intl ] )
    const langSwitchLabel = useMemo( () => intl.formatMessage({ description: 'AppHeader.Menu.LangSwitchTo', defaultMessage: 'Switch to' }), [ intl ] )

    const logo = useLogo()

    const auth = useAuth()
    const [ user, setUser ] = useState<User | undefined>()

    const onMenuSelect = useCallback( ( key: string | null ) => {
        if( !key ) return
        setExpanded( false )
        if( key === 'mycontent' ) navigate( intl.locale === 'cs-CZ' ? 'mujobsah' : '/mycontent' )
        else if( key === 'profile' ) navigate( intl.locale === 'cs-CZ' ? '/profil' : '/profile' )
        else if( key === 'logout' ) {
            auth.logout()
            navigate( '/' )
        } else if( LANG_LIST.filter( lang => lang.isoCode === key).length === 1 ) {
            i18n.onLangChange( key )
        } else if( [ 'dashboard', 'contacts', 'orders', 'vouchers', 'subscriptions' ].includes( key ) ) navigate( `/admin/${ key }` )
    }, [ auth, i18n, navigate, intl ] ) 

    useEffect( () => {
        if( auth ) setUser( auth.getUser() )
    }, [ auth ] )

    const [ expanded, setExpanded ] = useState( false )

    return(
        <Container>
            <Navbar variant='light' sticky='top' expand='lg' collapseOnSelect expanded={ expanded }>
                <Navbar.Brand><Link to='/'><img src={ logo.src } width='150' alt='Albert Levíček' /></Link></Navbar.Brand>
                <Navbar.Toggle aria-controls='main-navbar' onClick={ () => setExpanded( true ) }/>
                <Navbar.Offcanvas id='main-navbar' placement='end'>
                    <Offcanvas.Header closeButton onClick={ () => setExpanded( false ) }></Offcanvas.Header>
                    <Offcanvas.Body>
                        <Nav variant='underline' className='w-100 justify-content-end' activeKey={ i18n.lang.isoCode } onSelect={ key => onMenuSelect( key) }>
                            <Nav.Item className='mx-2'><Nav.Link as={ Link } to={ intl.locale === 'cs-CZ' ? '/fotoobrazy' : '/photos' } onClick={ () => setExpanded( false ) }>{ intl.formatMessage({ description: 'App.Menu.Photos', defaultMessage: 'Photos' }) }</Nav.Link></Nav.Item>
                            <Nav.Item className='mx-2'><Nav.Link as={ Link } to={ intl.locale === 'cs-CZ' ? '/sluzby' : '/services' } onClick={ () => setExpanded( false ) }>{ intl.formatMessage({ description: 'App.Menu.Services', defaultMessage: 'Services' }) }</Nav.Link></Nav.Item>
                            <Nav.Item className='btn btn-info mx-2 p-0'><Nav.Link as={ Link } to='mailto: albert@levicek.cz' onClick={ () => setExpanded( false ) }>{ intl.formatMessage({ description: 'App.Menu.Contact', defaultMessage: 'Contact' }) }</Nav.Link></Nav.Item>
                            { /*user && (user.roles.includes( 'admin' ) || user.roles.includes( 'agent' )) && <NavDropdown title='Admin' align='end'>
                                <NavDropdown.Item key='dashboard' eventKey='dashboard'>Dashboard</NavDropdown.Item>
                                <NavDropdown.Item key='contacts' eventKey='contacts'>Contacts</NavDropdown.Item>
                                <NavDropdown.Item key='orders' eventKey='orders'>Orders</NavDropdown.Item>
                                <NavDropdown.Item key='vouchers' eventKey='vouchers'>Vouchers</NavDropdown.Item>
                                <NavDropdown.Item key='subscriptions' eventKey='subscriptions'>Subscriptions</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item key='packages' eventKey='packages'>Packages</NavDropdown.Item>
                                <NavDropdown.Item key='content' eventKey='content'>Content</NavDropdown.Item>
                                <NavDropdown.Item key='mailer' eventKey='mailer'>Mailer</NavDropdown.Item>
                            </NavDropdown>*/ }
                            { LANG_LIST.length > 1 && <NavDropdown title={ i18n.lang.label } align='end'>{
                                LANG_LIST.filter( lang => lang.isoCode !== i18n.lang.isoCode ).map( ( lang ) => <NavDropdown.Item key={ lang.isoCode } eventKey={ lang.isoCode }>{ langSwitchLabel } { lang.label }</NavDropdown.Item> )
                            }</NavDropdown> }
                            { user 
                                ? <NavDropdown title={ user.name } align='end'>{<>
                                    <NavDropdown.Item key='mycontent' eventKey='mycontent'>{ myContentMenuLabel }</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item key='logout' eventKey='logout'>{ logoutMenuLabel }</NavDropdown.Item>
                                    <NavDropdown.Item key='profile' eventKey='profile'>{ profileMenuLabel }</NavDropdown.Item>
                                </>}</NavDropdown>
                                : <Nav.Item><Nav.Link as={ Link } to={ intl.locale === 'cs-CZ' ? '/prihlasit' : 'log-in' } title={ loginMenuTitle } onClick={ () => setExpanded( false ) }><BsFillPersonFill style={{ fontSize: '1.2rem' }} /></Nav.Link></Nav.Item>
                            }
                        </Nav>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>
            </Navbar>
        </Container>
    )
}
